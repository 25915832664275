/* eslint-disable react/jsx-no-bind */
import React, {
    Suspense,
    lazy,
    useEffect
}                                from 'react';
import { Provider }              from 'react-redux';
import {
    Router,
    Switch,
    Route,
    Redirect,
    useLocation
}                                from 'react-router-dom';
import { store }                 from './store';
import history                   from './history.js';
import MainLayout                from './layouts/MainLayout';
import { ModalProvider }         from './context/ModalProvider.js';
import { LoadingProvider }       from './context/LoadingProvider.js';
import { CheckoutStateProvider } from './context/CheckoutStateProvider';
import { useModal }              from './utils/useModal';

import 'lazysizes';

const AboutUs = lazy(() => import('./pages/AboutUs'));
const Contact = lazy(() => import('./pages/Contact'));
const Vacancies = lazy(() => import('./pages/Vacancies'));
const History = lazy(() => import('./pages/History'));
const Home = lazy(() => import('./pages/Home'));
const BlogSingle = lazy(() => import('./pages/BlogSingle'));
const Product = lazy(() => import('./pages/Product'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Catalog = lazy(() => import('./pages/Catalog'));
const PersonalArea = lazy(() => import('./pages/PersonalArea'));
const Blog = lazy(() => import('./pages/Blog'));
const InformationBuyers = lazy(() => import('./pages/InformationBuyers'));
const Search = lazy(() => import('./pages/Search'));
const DeliveryAndPayment = lazy(() => import('./pages/DeliveryAndPayment'));

function dummyLayout(props) {
    return props.children;
}

function AppRoute({ component: Page, name = '', layout, ...rest }) {   // eslint-disable-line react/prop-types
    const location = useLocation();
    const { toggleModal, activeModal } = useModal();

    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.init = false;

    window.lazySizes.init();

    useEffect(() => {
        if (activeModal) toggleModal({});
    }, [ location ]);

    return (
        <Route
            {...rest}
            render={props => {
                const Layout = layout ? layout : dummyLayout;

                return (
                    <MainLayout>
                        <Layout>
                            <Page {...props} name={name} />
                        </Layout>
                    </MainLayout>
                );
            }}
        />
    );
}

function App() {
    return (
        <LoadingProvider>
            <Provider store={store} >
                <Suspense fallback={null}>
                    <Router history={history}>
                        <ModalProvider>
                            <CheckoutStateProvider>
                                <Switch>
                                    <AppRoute
                                        path='/'
                                        component={Home}
                                        exact
                                    />

                                    <AppRoute
                                        path='/product/:id'
                                        component={Product}
                                        exact
                                    />

                                    <AppRoute
                                        path={'/personal-area'}
                                        component={PersonalArea}
                                        exact
                                    />

                                    <AppRoute
                                        path={'/history'}
                                        component={History}
                                        exact
                                    />

                                    <AppRoute
                                        path='/catalog/:id'
                                        component={Catalog}
                                        exact
                                    />

                                    <AppRoute
                                        path='/discounts'
                                        name='sale'
                                        component={Catalog}
                                        exact
                                    />

                                    <AppRoute
                                        path='/search'
                                        component={Search}
                                        exact
                                    />

                                    <AppRoute
                                        path='/contact'
                                        component={Contact}
                                        exact
                                    />


                                    <AppRoute
                                        path='/vacancies'
                                        component={Vacancies}
                                        exact
                                    />

                                    <AppRoute
                                        path='/about-us'
                                        component={AboutUs}
                                        exact
                                    />

                                    <AppRoute
                                        path='/blog'
                                        component={Blog}
                                        exact
                                    />

                                    <AppRoute
                                        path={'/blog-single'}
                                        component={BlogSingle}
                                        exact
                                    />

                                    <AppRoute
                                        path={'/checkout'}
                                        component={Checkout}
                                        exact
                                    />

                                    <AppRoute
                                        path={'/information-buyers'}
                                        component={InformationBuyers}
                                        exact
                                    />

                                    <AppRoute
                                        path={'/delivery-and-payment'}
                                        component={DeliveryAndPayment}
                                        exact
                                    />

                                    <Redirect to='/' />
                                </Switch>
                            </CheckoutStateProvider>
                        </ModalProvider>
                    </Router>
                </Suspense>
            </Provider>
        </LoadingProvider>
    );
}

export default App;
