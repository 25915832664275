import ACTIONS from '../constants/actionsTypes';

const initialState = {
    isLoading : false,
    errors    : {}
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.CREATE_ORDER_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading : false
            };
        case ACTIONS.CREATE_ORDER_ERROR:
            return {
                ...state,
                isLoading : false,
                errors    : data
            };
        case ACTIONS.CHANGE_ORDER_ERROR:
            return {
                ...state,
                isLoading : false,
                errors    : data
            };
        default:
            return state;
    }
};
