export default {
    GET_PRODUCTS_REQUEST : 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS : 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_ERROR   : 'GET_PRODUCTS_ERROR',
    CLEAR_ACCOUNTS_LIST  : 'CLEAR_PRODUCTS_LIST',

    GET_PRODUCTS_ANALOGS_REQUEST : 'GET_PRODUCTS_ANALOGS_REQUEST',
    GET_PRODUCTS_ANALOGS_SUCCESS : 'GET_PRODUCTS_ANALOGS_SUCCESS',
    GET_PRODUCTS_ANALOGS_ERROR   : 'GET_PRODUCTS_ANALOGS_ERROR',

    GET_PERSONAL_AREA_REQUEST : 'GET_PERSONAL_AREA_REQUEST',
    GET_PERSONAL_AREA_SUCCESS : 'GET_PERSONAL_AREA_SUCCESS',
    GET_PERSONAL_AREA_ERROR   : 'GET_PERSONAL_AREA_ERROR ',

    GET_PERSONAL_AREA_LAST_REQUEST : 'GET_PERSONAL_AREA_LAST_REQUEST',
    GET_PERSONAL_AREA_LAST_SUCCESS : 'GET_PERSONAL_AREA_LAST_SUCCESS',
    GET_PERSONAL_AREA_LAST_ERROR   : 'GET_PERSONAL_AREA_LAST_ERROR',

    GET_PERSONAL_AREA_INFO_REQUEST : 'GET_PERSONAL_AREA_INFO_REQUEST',
    GET_PERSONAL_AREA_INFO_SUCCESS : 'GET_PERSONAL_AREA_INFO_SUCCESS',
    GET_PERSONAL_AREA_INFO_ERROR   : 'GET_PERSONAL_AREA_INFO_ERROR',

    GET_PERSONAL_AREA_CHANGE_PASSWORD_REQUEST : 'GET_PERSONAL_AREA_CHANGE_PASSWORD_REQUEST',
    GET_PERSONAL_AREA_CHANGE_PASSWORD_SUCCESS : 'GET_PERSONAL_AREA_CHANGE_PASSWORD_SUCCESS',
    GET_PERSONAL_AREA_CHANGE_PASSWORD_ERROR   : 'GET_PERSONAL_AREA_CHANGE_PASSWORD_ERROR',

    GET_PERSONAL_AREA_UPDATE_INFO_REQUEST : 'GET_PERSONAL_AREA_UPDATE_INFO_REQUEST',
    GET_PERSONAL_AREA_UPDATE_INFO_SUCCESS : 'GET_PERSONAL_AREA_UPDATE_INFO_SUCCESS',
    GET_PERSONAL_AREA_UPDATE_INFO_ERROR   : 'GET_PERSONAL_AREA_UPDATE_INFO_ERROR',

    GET_PRODUCTS_AVAILABILITY_REQUEST : 'GET_PRODUCTS_AVAILABILITY_REQUEST',
    GET_PRODUCTS_AVAILABILITY_SUCCESS : 'GET_PRODUCTS_AVAILABILITY_SUCCESS',
    GET_PRODUCTS_AVAILABILITY_ERROR   : 'GET_PRODUCTS_AVAILABILITY_ERROR',

    GET_SEARCH_PRODUCTS_REQUEST      : 'GET_SEARCH_PRODUCTS_REQUEST',
    GET_SEARCH_PRODUCTS_SUCCESS      : 'GET_SEARCH_PRODUCTS_SUCCESS',
    GET_SEARCH_PRODUCTS_ERROR        : 'GET_SEARCH_PRODUCTS_ERROR',
    CLEAR_SEARCH_LIST                : 'CLEAR_SEARCH_LIST',
    SEARCH_PRODUCTS_LIST_CHANGE_PAGE : 'SEARCH_PRODUCTS_LIST_CHANGE_PAGE',

    GET_CATALOG_TREE_REQUEST : 'GET_CATALOG_TREE_REQUEST',
    GET_CATALOG_TREE_SUCCESS : 'GET_CATALOG_TREE_SUCCESS',
    GET_CATALOG_TREE_ERROR   : 'GET_CATALOG_TREE_ERROR',

    GET_CATALOG_REQUEST : 'GET_CATALOG_REQUEST',
    GET_CATALOG_SUCCESS : 'GET_CATALOG_SUCCESS',
    GET_CATALOG_ERROR   : 'GET_CATALOG_ERROR',
    CATALOG_CHANGE_PAGE : 'CATALOG_CHANGE_PAGE',


    GET_FILTER_CATALOG_REQUEST : 'GET_FILTER_CATALOG_REQUEST',
    GET_FILTER_CATALOG_SUCCESS : 'GET_FILTER_CATALOG_SUCCESS',
    GET_FILTER_CATALOG_ERROR   : 'GET_FILTER_CATALOG_ERROR',

    GET_CATALOG_ACTIVE_CHECKBOX    : 'GET_CATALOG_ACTIVE_CHECKBOX',
    REMOVE_CATALOG_ACTIVE_CHECKBOX : 'REMOVE_CATALOG_ACTIVE_CHECKBOX',
    MOUNT_CATALOG_ACTIVE_CHECKBOX  : 'MOUNT_CATALOG_ACTIVE_CHECKBOX',
    CHANGE_SORTING                 : 'CHANGE_SORTING',
    CHANGE_PRICE_RANGE             : 'CHANGE_PRICE_RANGE',
    REMOVE_CHANGE_PRICE_RANGE      : 'REMOVE_CHANGE_PRICE_RANGE',


    GET_CART_REQUEST : 'GET_CART_REQUEST',
    GET_CART_SUCCESS : 'GET_CART_SUCCESS',
    GET_CART_ERROR   : 'GET_CART_ERROR',

    GET_ADD_IN_CART_REQUEST : 'GET_ADD_IN_CART_REQUEST',
    GET_ADD_IN_CART_SUCCESS : 'GET_ADD_IN_CART_SUCCESS',
    GET_ADD_IN_CART_ERROR   : 'GET_ADD_IN_CART_ERROR',

    GET_PRODUCT_RECOMMENDATIONS_REQUEST : 'GET_PRODUCT_RECOMMENDATIONS_REQUEST',
    GET_PRODUCT_RECOMMENDATIONS_SUCCESS : 'GET_PRODUCT_RECOMMENDATIONS_SUCCESS',
    GET_PRODUCT_RECOMMENDATIONS_ERROR   : 'GET_PRODUCT_RECOMMENDATIONS_ERROR',

    GET_PRODUCT_BEST_REQUEST : 'GET_PRODUCT_BEST_REQUEST',
    GET_PRODUCT_BEST_SUCCESS : 'GET_PRODUCT_BEST_SUCCESS',
    GET_PRODUCT_BEST_ERROR   : 'GET_PRODUCT_BEST_ERROR',

    GET_HERO_BANNER_REQUEST : 'GET_HERO_BANNER_REQUEST',
    GET_HERO_BANNER_SUCCESS : 'GET_HERO_BANNER_SUCCESS',
    GET_HERO_BANNER_ERROR   : 'GET_HERO_BANNER_ERROR',

    GET_HERO_BANNER_SLIDER_REQUEST : 'GET_HERO_BANNER_SLIDER_REQUEST',
    GET_HERO_BANNER_SLIDER_SUCCESS : 'GET_HERO_BANNER_SLIDER_SUCCESS',
    GET_HERO_BANNER_SLIDER_ERROR   : 'GET_HERO_BANNER_SLIDER_ERROR',

    GET_HERO_BANNER_GROUP_REQUEST : 'GET_HERO_BANNER_GROUP_REQUEST',
    GET_HERO_BANNER_GROUP_SUCCESS : 'GET_HERO_BANNER_GROUP_SUCCESS',
    GET_HERO_BANNER_GROUP_ERROR   : 'GET_HERO_BANNER_GROUP_ERROR',

    GET_CITIES_REQUEST : 'GET_CITIES_REQUEST',
    GET_CITIES_SUCCESS : 'GET_CITIES_SUCCESS',
    GET_CITIES_ERROR   : 'GET_CITIES_ERROR',

    GET_CURRENT_CITY : 'GET_CURRENT_CITY',
    SET_DISTRICT     : 'SET_DISTRICT',

    SET_CHECKOUT_STATE   : 'SET_CHECKOUT_STATE',
    CLEAR_CHECKOUT_STATE : 'CLEAR_CHECKOUT_STATE',

    CREATE_ORDER_REQUEST : 'CREATE_ORDER_REQUEST',
    CREATE_ORDER_SUCCESS : 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_ERROR   : 'CREATE_ORDER_ERROR',
    CHANGE_ORDER_ERROR   : 'CHANGE_ORDER_ERROR',

    GET_VISUALLY_IMPAIRED : 'GET_VISUALLY_IMPAIRED',

    GET_OFFER_REQUEST : 'GET_OFFER_REQUEST',
    GET_OFFER_SUCCESS : 'GET_OFFER_SUCCESS',
    GET_OFFER_ERROR   : 'GET_OFFER_ERROR',

    GET_SHOWCASES_REQUEST : 'GET_SHOWCASES_REQUEST',
    GET_SHOWCASES_SUCCESS : 'GET_SHOWCASES_SUCCESS',
    GET_SHOWCASES_ERROR   : 'GET_SHOWCASES_ERROR'
};
