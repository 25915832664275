import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import PercentIcon from '../../assets/icon/PercentIcon';
import ArrowNavRight from '../../assets/icon/ArrowNavRight';
import ExpertPharmacist from '../ExpertPharmacist';
import style from './NavigationPopup.module.scss';

function Mobile({ list, onClickLink }) {
    const list_wrapper = useRef(null);
    const step_second = useRef(null);
    const step_last = useRef(null);


    const [ activeTab, setActiveTab ] = useState({
        id   : '',
        step : 1
    });

    const [ lastChild, setLastChild ] = useState([]);

    const showTab = useCallback(async e => {
        const { target } = e;
        const id = +target.closest('li').getAttribute('data-id');
        const back = target.closest('li').getAttribute('data-show');
        const backAllTitle = target.closest('li').getAttribute('data-backalltitle');
        const last = target.closest('li').getAttribute('data-last');
        const parent =  +target.closest('li').getAttribute('data-parent');

        if (back) {
            e.preventDefault();
            await setActiveTab({
                id   : list[0]?.id,
                step : 1
            });

            if (!list_wrapper.current) return;

            list_wrapper.current.style.height = 'auto';
            window.scrollTo(0, 0);

            return;
        }

        if (backAllTitle) {
            await setActiveTab({
                id,
                step : 2
            });

            // eslint-disable-next-line more/no-duplicated-chains

            if (!list_wrapper.current) return;

            // eslint-disable-next-line more/no-duplicated-chains
            list_wrapper.current.style.height = `${step_second.current.offsetHeight - 28}px`;

            window.scrollTo(0, 0);

            return;
        }

        if (last) {
            list.forEach(item => {
                if (item.id === parent) {
                     item?.children?.forEach(child_last => {
                            if (child_last.id === id) {
                                // eslint-disable-next-line max-nested-callbacks
                                const arr = child_last?.children?.map(last_item => {
                                    return { ...last_item, parent_id: item.id, title: child_last?.nameUa };
                                }) || [ child_last ];

                                setLastChild(arr);
                            }
                        });
                }
            });

            await setActiveTab({
                id,
                step : 3
            });

            if (!list_wrapper.current) return;

            // eslint-disable-next-line more/no-duplicated-chains
            list_wrapper.current.style.height = `${step_last.current.offsetHeight - 28  }px`;
            window.scrollTo(0, 0);

            return;
        }

        if (!last && !back) {
            e.preventDefault();
            await setActiveTab({
                id,
                step : 2
            });

            if (!list_wrapper.current) return;
            // eslint-disable-next-line more/no-duplicated-chains
            list_wrapper.current.style.height = `${step_second.current.offsetHeight - 28}px`;

            window.scrollTo(0, 0);
        }
    }, [ activeTab, step_last, step_second ]);

    useEffect(() => {
        if (!list.length) return;

        setActiveTab({
            id   : list[0]?.id,
            step : 1
        });
    }, [ list ]);

    return (
        <div className={style.mobile}>
            <nav className={style.nav}>
                {/* eslint-disable-next-line react/no-string-refs */}
                <ul ref={list_wrapper} className={style.overflow}>
                    <li>
                        <Link to={'/discounts'}>
                            Пропозиції

                            <PercentIcon />
                        </Link>
                    </li>

                    {
                        list?.map(item => (
                            <li
                                key={item?.id}
                                data-id={item?.id}
                                onClick={showTab}>
                                <a href={'#'}>
                                    { item?.nameUa }

                                    <ArrowNavRight />
                                </a>

                                <CSSTransition
                                    timeout={300}
                                    in={activeTab?.id === item?.id && activeTab?.step === 2}
                                    classNames='transform-x'
                                    unmountOnExit
                                    mode={'out-in'}
                                    >
                                    {/* eslint-disable-next-line no-undef */}
                                    <ul
                                        ref={step_second}
                                        className={`${style.child_title  } step-2`}
                                    >
                                        <li className={style.all_category}  data-show={'all'}>
                                            <ArrowNavRight />
                                            <button>
                                                Усі категорії
                                            </button>
                                        </li>

                                        {
                                            item?.children?.map(child => (
                                                <li
                                                    className={style.child_item}
                                                    key={child?.id}
                                                    data-parent={item?.id}
                                                    data-last={'last'}
                                                    data-id={child?.id}
                                                >
                                                    <h2
                                                        onClick={!child?.children ? onClickLink.bind(null, child?.id) : null}
                                                        >{child?.nameUa}
                                                    </h2>

                                                    {child?.children && child?.children?.length &&   <ArrowNavRight /> }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </CSSTransition>

                                <CSSTransition
                                    timeout={300}
                                    in={activeTab?.step === 3}
                                    classNames='transform-x'
                                    unmountOnExit
                                    mode={'out-in'}
                                >
                                    <ul
                                        ref={step_last}
                                        className={`${style.last_child_list  } step-3`}>
                                        <li
                                            className={style.all_category}
                                            data-id={lastChild[0]?.parent_id}
                                            data-backalltitle={'all'}>
                                            <ArrowNavRight />
                                            <button>
                                                {lastChild[0]?.title}
                                            </button>
                                        </li>

                                        {
                                                lastChild?.map(child => (
                                                    <li
                                                        className={style.child_item}
                                                        key={child?.id}
                                                        data-id={child?.id}
                                                    >
                                                        <span
                                                            onClick={onClickLink.bind(null, child?.id)}
                                                            className={style.link}
                                                    >
                                                            {child?.nameUa}
                                                        </span>
                                                    </li>
                                                ))
                                            }
                                    </ul>
                                </CSSTransition>
                            </li>
                        ))
                    }
                </ul>

                <ExpertPharmacist />
            </nav>
        </div>
    );
}

Mobile.propTypes = {
    list        : PropTypes.array,
    onClickLink : PropTypes.func.isRequired
};

Mobile.defaultProps = {
    list : []
};

export default React.memo(Mobile);
